<template>
  <b-container fluid>


    <div class="top-buttons">
      <b-button class="b-steps" variant="dark">{{step}}/3 </b-button>

      <div id="step-1" v-if="step===1">

        Type of Stock to discard: &nbsp;&nbsp;

        <b-button variant="primary"
                  :disabled="discardType!= null"
                  @click="setDiscardType('Stock')"> Stock </b-button>

        &nbsp;&nbsp;&nbsp;

        <b-button variant="primary"
                  :disabled="discardType!= null"
                  @click="setDiscardType('Retention')"> Retention </b-button>

        &nbsp;&nbsp;&nbsp;

        <b-button variant="primary"
                  :disabled="discardType!= null"
                  @click="setDiscardType('Shipment')"> Shipment </b-button>
      </div>

      <div id="discard">
        <b-button class="discardType" v-if="discardType!=null" variant="dark">{{discardType}}</b-button>
      </div>

      <div id="action">
        <b-button class="b-reset" v-if="step>=2"
                  variant="primary"
                  @click="clearAll"> RESET ALL </b-button>
      </div>

    </div>




    <div id="step-2" class="steps" v-show="step==2">

      <inputTextForm v-if="!form.enableScanner"
          v-bind:edit="true"
          v-bind:label="'Enter codes: PMI_CODE <space> BATCH <space> EXPIRATION_DATE (yyyy-mm-dd)'"
          v-bind:label_enter="'ENTER'"
          v-bind:var_name="'pmi_code_batch'"
          v-bind:var_value="form.pmi_code_batch"
          v-bind:optional="false"
          v-bind:event_button="true"
          @onEnterValue="onEnter"
          @keyup.enter.native="onEnter"
                     style="background: khaki; padding: 5px "
      />

      <b-button variant="primary"
                v-if="form.enableScanner"
                @click="switchInput"> SWITCH TO MANUAL INPUT </b-button>


      <b-button variant="primary"
                v-if="!form.enableScanner"
                @click="switchInput"> SWITCH TO BARCODE READER </b-button>

      {{codeEscanned}}
      <br/>
      <!-- TABLE CONTENT -->
      <b-table-simple

                      stacked="md"
                      show-empty
                      small
                      striped

                      v-if="flavorsToDiscard.length>0"
      >
        <b-thead>
          <b-tr>
            <b-th>PMI CODE</b-th>
            <b-th>BATCH</b-th>
            <b-th>EXPIRATION DATE</b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="i of flavorsToDiscard" :key="i">
            <b-td>{{i.pmi_code}}</b-td>
            <b-td>{{i.batch}}</b-td>
            <b-td>{{i.expiration_date}}</b-td>
            <b-td>
              <b-button variant="primary" @click="remove(i.codes)"><b-icon-trash /></b-button>

            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-button  v-if="flavorsToDiscard.length>0"
                variant="primary"
                @click="discardAll"> DISCARD ALL </b-button>
    </div>

  </b-container>
</template>


<style>
.top-buttons {
  margin: 0px;
  padding: 5px;
  background: silver;

  align-items: center;
  display: flex;

}

.b-steps{
  float: left;
  cursor: not-allowed !important;
}

.b-reset {
  flex: 1;
  float: right;
}
.discardType {
  cursor: not-allowed !important;
}

.steps {
  margin-top: 0px;
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
}

#step-1 {
  margin: 0px;
  flex: 200;
}

#action {
  margin: 0px;
  flex: 1;
}

#discard {
  margin: 0px;
  flex: 9;
}


.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

</style>

<script>

import utils from '@/utils'
import {mapActions, mapGetters, mapState} from "vuex";
import inputTextForm from '@/views/components/forms/Form.input.text'
import moment from 'moment'

export default {
  components: {
    inputTextForm,
   // inputCheckbox
  },
  data() {
    return {
      step: 1,
      discardType: null, //sample_type
      listOfCodes: {},
      flavorsToDiscard: [],
      form: {
        pmi_code_batch: null,
        enableScanner: true
      },
      codeEscanned: ''
    }
  },
  computed: {
    ...mapState(['user','flavor_list']),
    ...mapGetters(['user','flavor_list']),
  },
  mounted() {
      document.addEventListener('keydown', this.onBarCodeEnter);
  },
  beforeDestroy() {
      document.removeEventListener('keydown', this.onBarCodeEnter);
  },
  methods: {
    ...mapActions(['getUser', 'getFlavorLists', 'discardFlavors']),
    switchInput(){
      this.form.enableScanner = !this.form.enableScanner;
      if (this.form.enableScanner) {
        document.addEventListener('keydown', this.onBarCodeEnter);
      }
      else {
        document.removeEventListener('keydown', this.onBarCodeEnter);
      }
    },
    onBarCodeEnter(event){
      // console.log('enabled BARCODE');
      if( event.keyCode < 32) {
        event.preventDefault();
        console.log(' special key received code = ' + event.keyCode)
        console.log(event.key);
      }
      else {
        // console.log(event);
        if (event.key === ']') {
          console.log('Alt received')
          const values = this.codeEscanned.split('|')
          if (values.length === 3) {
            this._setListCodes(this.codeEscanned, values);
            this.codeEscanned = '';

          }
        }
        else if (event.key.length === 1 && event.key != '[') {
          this.codeEscanned = this.codeEscanned + event.key;
        }
        else{
          console.log('....more then 1 chars received ')
          console.log(event.key);
        }

      }
    },
    setDiscardType(type){
      this.discardType=type;
      this.step=2;
    },
    clearAll(){
      this.step=1;
      this.discardType = null;
      this.listOfCodes =  {};
      this.flavorsToDiscard = [];
      this.codeEscanned = '';
    },
    onEnter(value){

      let codes = null;

      if (utils.isAnObject(value) && !utils.isUndefined(value.target)){
        codes = value.target.value;
      }
      else { codes = value.value; }

      if (utils.isNotUndefined(codes)) {
        const values = codes.split(' ');
        this._setListCodes(codes, values);
      }

    },
    _setListCodes(codes, values){

      console.log(codes);

      let e_date = (values[2].length > 8)? moment(values[2]).format('YYYY-MM-DD'): null;

      if (e_date == 'Invalid date') {
        e_date = moment(values[2]).format('DD-MMM-YYYY');
      }

      this.listOfCodes[codes] = {
        pmi_code : values[0]
        , batch : values[1]
        , expiration_date: e_date
        , sample_type: this.discardType
        , codes: codes
      };

      //this.listOfCodes = [...new Set(this.listOfCodes)];
      //console.log(Object.values(this.listOfCodes))

      this.flavorsToDiscard = Object.values(this.listOfCodes);
      this.form.pmi_code_batch = '';

      console.log(this.flavorsToDiscard);
      this.$forceUpdate();
      console.log(' ... readed done');
    },
    discardAll(){
      //this.step=3;
      this.discardFlavors(this.flavorsToDiscard);
      this.clearAll();
    },
    remove(codes){
      delete this.listOfCodes[codes];
      this.flavorsToDiscard = Object.values(this.listOfCodes);
    }
  }
}

</script>
